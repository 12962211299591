import client from "../../lib/http-common";

export const writeToUsApi = (data) => {
  return client.post("/write-to-us", data);
};

export const newsletterApi = (data) => {
  return client.post("/newsletter-subscription", data);
};

export const letsConnectApi = (data) => {
  return client.post("/lets-connect", data);
};

export const jobApplicationApi = (data) => {
  const formData = new FormData();
  formData.append("resume", data.resume_file);
  return client.post(
    `/job-application?first_name=${data.first_name}&last_name=${data.last_name}&email=${data.email}&contact_no=${data.contact_no}&cover_letter=${data.cover_letter}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const esgFormApi = (data) => {
  return client.post("/esg-download", data);
};

export const webinarRegistrationCall = (data) => {
  return client.post("/webinar-registration", data);
};
export const contactApi = (data) => {
  return client.post("/contact-us", data);
};

export const sbaFormApi = (data) => {
  return client.post("/sba-form", data);
};

export const crmFormApi = (data) => {
  return client.post("/crm-form", data);
};
