import React, { useEffect, useState } from "react";

import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Aos from "aos";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Field, Form } from "formik";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Alert as MuiAlert } from "@material-ui/lab";
import LoadingButton from "@mui/lab/LoadingButton";
import flags from "react-phone-number-input/flags";
import bgimg from "../../asset/images/oracle/contact_us.webp";
import CustomePhoneNumber from "../CustomePhoneNumber_Not_Mandatory";
import { sbaFormApi } from "../../Component/api/api";

import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../Style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";

export default function OracleContactUs() {
  localStorage.setItem("isOpen", 1);
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  
  const [loader, setLoader] = React.useState(false); 
  const [checkboxFlag, setCheckboxFlag] = React.useState(false);
  const [phoneValueError, setPhoneValueError] = React.useState();
  const [phoneValue1, setPhoneValue1] = React.useState();
  const [checkboxError, setCheckboxError] = React.useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const emailreg =
    /^[a-zA-Z0-9_]+(?:[-._][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+(?:[-.][a-zA-Z0-9_]+)*\.[a-zA-Z]{2,}$/;
  const nameValidation = /^[A-Za-z\s]+$/;
  const organizationReg = /^[a-zA-Z0-9 ,-./!@#$%^&*~]*$/;
  const indiaPhoneRegex = /^[6-9]\d{9}$/;

  const history = useHistory();

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    organization: "",
    message: "",
    phonevalue1: "",
    contact_us_type: "",
    tandc: true,
  };

  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .typeError("Required field")
      .required("First name is required")
      .max(100)
      .matches(nameValidation, "Enter valid first name"),
    lastname: yup
      .string()
      .typeError("Required field")
      .required("Last name is required")
      .max(100)
      .matches(nameValidation, "Enter valid last name"),
    email: yup
    .string()
    .typeError("Required field")
    .required("Email is required")
    .matches(emailreg, "Please enter a valid email address")
    ,
    phonevalue1: yup.string(),
    organization: yup
      .string()
      .typeError("Required field")
      .required("Organization is required")
      .matches(organizationReg, "Enter valid organization name"),
    message: yup.string().max(200),
    tandc: yup
      .bool()
      .oneOf([true], "You must accept the terms and conditions")
      .nullable(),
  });

  const handleKeyUp = (event, fieldName) => {
    const value = event.target.value;
    if (/\d+/.test(value)) {
      if (fieldName === "firstname") {
        setFirstNameError("First name should not contain numbers");
      } else if (fieldName === "lastname") {
        setLastNameError("Last name should not contain numbers");
      }
    } else {
      if (fieldName === "firstname") {
        setFirstNameError("");
      } else if (fieldName === "lastname") {
        setLastNameError("");
      }
    }
  };

  const onSubmitt = (values) => {
    console.log(values);
    isPhoneValid();
    if (phoneValueError) {
      return;
    }

    const formData = {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email,
      contact_no: values.phonevalue1,
      message: values.message,
      company: values.organization,
      contact_us_type: "Oracle", 
      
    };
    setCheckboxError("");
    handleFormSubmit(formData);
  };

  const handleFormSubmit = (values) => {
    console.log(values);
    post(values);
  };
 
  const isPhoneValid = () => {
    if (!phoneValue1) {
      setPhoneValueError("");
      return;
    }

    var phoneValue2 = phoneValue1.includes("+91")
      ? phoneValue1.substring(3)
      : phoneValue1;
    if (phoneValue2.trim() === "") {
      setPhoneValueError("Enter phone number");
      return;
    }

    if (isValidPhoneNumber(phoneValue1)) {
      if (phoneValue1.includes("+91")) {
        if (!indiaPhoneRegex.test(phoneValue2)) {
          setPhoneValueError("Invalid phone number");
          return;
        }
      }
      setPhoneValue1(phoneValue1);
      setPhoneValueError("");
    } else {
      setPhoneValueError("Invalid phone number");
    }
  };

  const onSucccessCall = () => {
    //localStorage.setItem("isOpen", 0);
    setCheckboxFlag(false); 
    history.push("/oracle-form-submit");
  };

  const post = async (formData) => {
    setLoader(true);
    const message = "Oops, something went wrong, Please try again leter.";
    try {
      const { data, status } = await sbaFormApi(formData);
      if (status === 200) {
        if (data.success === true) {
          onSucccessCall();
        } else {
          toast.error(data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } catch (error) {
      toast.error(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoader(false);
  };

  return (
    <>
      <Box
        id="oraclecontactus"
        className="section section_mob"
        component="section"
      >
        <Container maxWidth="lg" className="bdr">
          <Grid container spacing={1} alignItems="top">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="al_center">
                <Typography variant="h2" className="black">
                  Contact Us
                </Typography>

                <Typography variant="h6" className="black" gutterBottom>
                  Discover how our innovative solutions can transform your
                  business. Get in touch with our team of experts today.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box className="oraContact whitebx" mt={2} mb={2}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="">
                  <img src={bgimg} alt="contact us" className="topbanner2" />
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <Formik
                
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitt}
                    validateOnChange={true}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldError,
                      isSubmitting,
                      touched,
                      errors,
                    }) => (
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className="alltxfieldlg ">
                              <Field
                                as={TextField}
                                id="filled-basic1"
                                label="First Name *"
                                variant="standard"
                                fullWidth
                                inputProps={{ maxLength: 100 }}
                                name="firstname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyUp={(event) =>
                                  handleKeyUp(event, "firstname")
                                }
                                error={
                                  touched.firstname && Boolean(errors.firstname)
                                }
                                helperText={
                                  touched.firstname && errors.firstname
                                    ? errors.firstname
                                    : firstNameError
                                }
                                FormHelperTextProps={{
                                  style: { color: "red" },
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className="alltxfieldlg">
                              <Field
                                as={TextField}
                                id="filled-basic2"
                                label="Last Name *"
                                variant="standard"
                                fullWidth
                                inputProps={{ maxLength: 100 }}
                                name="lastname"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyUp={(event) =>
                                  handleKeyUp(event, "lastname")
                                }
                                error={
                                  touched.lastname && Boolean(errors.lastname)
                                }
                                helperText={
                                  touched.lastname && errors.lastname
                                    ? errors.lastname
                                    : lastNameError
                                }
                                FormHelperTextProps={{
                                  style: { color: "red" },
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className="alltxfieldlg ">
                              <Field
                                as={TextField}
                                id="filled-basic3"
                                label="Email Address *"
                                variant="standard"
                                name="email"
                                inputProps={{ maxLength: 100 }}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                FormHelperTextProps={{
                                  style: { color: "red" },
                                }}
                                fullWidth
                              />
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className="alltxfieldlg " mt={2}>
                              <Field
                                as={PhoneInput}
                                flags={flags}
                                international
                                name="phonevalue1" 
                                value={values.phonevalue1}
                                inputComponent={CustomePhoneNumber}
                                placeholder="Enter phone number"
                                onChange={(value) => {
                                  handleChange({
                                    target: {
                                      name: "phonevalue1",
                                      value: value,
                                    },
                                  });
                                  setPhoneValue1(value);
                                }}
                                onBlur={() => {
                                  handleBlur("phonevalue1");
                                  isPhoneValid();
                                }}
                                error={
                                  touched.PhoneValue1 &&
                                  Boolean(errors.PhoneValue1)
                                }
                                helperText={
                                  touched.PhoneValue1 && errors.PhoneValue1
                                    ? errors.PhoneValue1
                                    : phoneValueError
                                }
                                FormHelperTextProps={{
                                  style: { color: "red" },
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="alltxfieldlg">
                              <Field
                                as={TextField}
                                id="filled-basic4"
                                label="Organization Name *"
                                variant="standard"
                                fullWidth
                                name="organization"
                                error={
                                  touched.organization &&
                                  Boolean(errors.organization)
                                }
                                helperText={
                                  touched.organization && errors.organization
                                }
                                FormHelperTextProps={{
                                  style: { color: "red" },
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="alltxfieldlg">
                              <Field
                                as={TextField}
                                id="filled-basic5"
                                label="Write your message"
                                variant="standard"
                                fullWidth
                                name="message"
                                multiline
                                rows={4}
                              />
                            </Box>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="alltxfieldlg chkbx">
                              <FormControlLabel
                                control={
                                  <>
                                    <Field
                                      defaultChecked={true}
                                      as={Checkbox}
                                      name="tandc"
                                      color="primary"
                                      size="small"
                                    />
                                  </>
                                }
                                label={
                                  <Typography className="label2">
                                    By clicking submit, I agree to Decimal Point
                                    Analytics{" "}
                                    <Link to="/terms">Terms of Use</Link> and{" "}
                                    <Link to="/policy">Privacy Policy</Link>
                                  </Typography>
                                }
                              />
                            </Box>
                            <Box className="checkbx-error">
                              {touched.tandc && errors.tandc && (
                                <MuiAlert severity="error">
                                  {errors.tandc}
                                </MuiAlert>
                              )}
                            </Box>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center" mt={1}>
                              {loader ? (
                                <LoadingButton
                                  loading={true}
                                  variant="outlined"
                                  disabled
                                >
                                  disabled
                                </LoadingButton>
                              ) : (
                                <Button
                                  type="submit"
                                  onClick={() => {
                                    console.log(errors);
                                  }}
                                  variant="contained"
                                  className="dpabtn_1 mobbtn"
                                >
                                  Submit
                                </Button>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Box>
    </>
  );
}
