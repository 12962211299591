import img50 from "../../asset/images/insight/blog_thumbnails/navigating_climate_change.webp";
import img49 from "../../asset/images/insight/blog_thumbnails/transforming_financial.webp";
import img48 from "../../asset/images/insight/blog_thumbnails/enhancing_fraud.webp";
import img47 from "../../asset/images/insight/blog_thumbnails/using_machine_learning.webp";
import img46 from "../../asset/images/insight/blog_thumbnails/what_are_small.webp";
import img45 from "../../asset/images/insight/blog_thumbnails/emerging_trends_ai.webp";
import img44 from "../../asset/images/insight/blog_thumbnails/why_creditPulse.webp";
import img43 from "../../asset/images/insight/blog_thumbnails/how_portfolio_alignment.webp";
import img42 from "../../asset/images/insight/blog_thumbnails/harnessing_generative_ai.webp";
import img41 from "../../asset/images/insight/blog_thumbnails/how_to_levarage.webp";
import img40 from "../../asset/images/insight/blog_thumbnails/how_artificial_intelligence.webp";
import img39 from "../../asset/images/insight/blog_thumbnails/driving_growth.webp";
import img38 from "../../asset/images/insight/blog_thumbnails/data_automation.webp";
import img37 from "../../asset/images/insight/blog_thumbnails/digital_security.webp";
import img36 from "../../asset/images/insight/blog_thumbnails/how_ai_is_enabling.webp";
import img35 from "../../asset/images/insight/blog_thumbnails/revolutionizing_equity_research.webp";
import img34 from "../../asset/images/insight/blog_thumbnails/unlocking_the_potential.webp";
import img33 from "../../asset/images/insight/blog_thumbnails/scope_of_ai.webp";
import img31 from "../../asset/images/insight/blog_thumbnails/trends_and_advancements.webp";
import img30 from "../../asset/images/insight/blog_thumbnails/how_to_value.webp";
import img29 from "../../asset/images/insight/blog_thumbnails/building_process_efficiency.webp";
import img28 from "../../asset/images/insight/blog_thumbnails/advances_in_ai.webp";
import img27 from "../../asset/images/insight/blog_thumbnails/the_great_skills.webp";
import img26 from "../../asset/images/insight/blog_thumbnails/a_cautionery.webp";
import img25 from "../../asset/images/insight/blog_thumbnails/guiding_policymaking.webp";
import img24 from "../../asset/images/insight/blog_thumbnails/private_equity.webp";
import img23 from "../../asset/images/insight/blog_thumbnails/forging_ahead.webp";
import img22 from "../../asset/images/insight/blog_thumbnails/laplaces_demo.webp";
import img21 from "../../asset/images/insight/blog_thumbnails/iatrogenesis_of_social.webp";
import img20 from "../../asset/images/insight/blog_thumbnails/beginning_of_etf.webp";
import img19 from "../../asset/images/insight/blog_thumbnails/extreme_funding.webp";
import img18 from "../../asset/images/insight/blog_thumbnails/covid19.webp";
import img17 from "../../asset/images/insight/blog_thumbnails/research_operations.webp";
import img16 from "../../asset/images/insight/blog_thumbnails/from_bank_of.webp";
import img15 from "../../asset/images/insight/blog_thumbnails/do_we_really.webp";
import img14 from "../../asset/images/insight/blog_thumbnails/is_demonetization.webp";
import img13 from "../../asset/images/insight/blog_thumbnails/analyzing_gdp.webp";
import img12 from "../../asset/images/insight/blog_thumbnails/indias_coming_fiscal.webp";
import img11 from "../../asset/images/insight/blog_thumbnails/does_rbi.webp";
import img10 from "../../asset/images/insight/blog_thumbnails/india_deflation.webp";
import img9 from "../../asset/images/insight/blog_thumbnails/ai_enabling.webp";
import img8 from "../../asset/images/insight/blog_thumbnails/india_heading.webp";
import img7 from "../../asset/images/insight/blog_thumbnails/excessive_monetary_tightening.webp";
import img6 from "../../asset/images/insight/blog_thumbnails/emergence_of.webp";
import img5 from "../../asset/images/insight/blog_thumbnails/the_fallacy.webp";
import img4 from "../../asset/images/insight/blog_thumbnails/artificial_intelligence.webp";
import img3 from "../../asset/images/insight/blog_thumbnails/incentive_structure.webp";
import img2 from "../../asset/images/insight/blog_thumbnails/towards_collective.webp";
import img1 from "../../asset/images/insight/blog_thumbnails/do_we_really.webp";


import img51 from "../../asset/images/insight/blog_thumbnails/safeguarding_data_privacy.webp";
import img52 from "../../asset/images/insight/blog_thumbnails/navigating_market_volatality.webp";
import img53 from "../../asset/images/insight/blog_thumbnails/south_afrikas_remarkable_rise.webp";
import img54 from "../../asset/images/insight/blog_thumbnails/how_Analytics_is_Changing.webp";
import img55 from "../../asset/images/insight/blog_thumbnails/ai_in_esg_analysis.webp";
import img56 from "../../asset/images/insight/blog_thumbnails/navigating_credit_risk.webp";
import img57 from "../../asset/images/insight/blog_thumbnails/business_loan.webp";
import img58 from "../../asset/images/insight/blog_thumbnails/self_correcting_llm.webp";
import img59 from "../../asset/images/insight/blog_thumbnails/enhancing_operatinal_efficiency.webp";
import img60 from "../../asset/images/insight/blog_thumbnails/powerupyourfinances.webp"
import img61 from "../../asset/images/insight/blog_thumbnails/Key_Trends_For_AssetManagement.webp"
import img62 from "../../asset/images/insight/blog_thumbnails/llm_beyond_chatbots.webp"
import img63 from "../../asset/images/insight/blog_thumbnails/navigating_public_float.webp"
import img64 from "../../asset/images/insight/blog_thumbnails/future_wealth_management.webp"
import img65 from "../../asset/images/insight/blog_thumbnails/intelligent_document.webp"
import img66 from "../../asset/images/insight/blog_thumbnails/ai_ml_asset.webp"
import img67 from "../../asset/images/insight/blog_thumbnails/investment_decisions.webp"
import img68 from "../../asset/images/insight/blog_thumbnails/game_changer_strategy.webp"
import img69 from "../../asset/images/insight/blog_thumbnails/power_of_catastrophe.webp"
import img70 from "../../asset/images/insight/blog_thumbnails/building_a_superior_data.webp"
import img71 from "../../asset/images/insight/blog_thumbnails/top5trends.webp"
import img72 from "../../asset/images/insight/blog_thumbnails/ai_in_finance.webp"
import img73 from "../../asset/images/insight/blog_thumbnails/role_of_ai.webp"
import img74 from "../../asset/images/insight/blog_thumbnails/rent_role.webp"
import img75 from "../../asset/images/insight/blog_thumbnails/360_degree.webp"

import LeveragingAIforRentRoll from "./Blog/LeveragingAIforRentRoll";
import RoleofAIandML from "./Blog/RoleofAIandML";
import AIinFinance from "./Blog/AIinFinance";
import Top5Trends from "./Blog/Top5Trends";
import BuildingASuperiorDataDrivenCatModeling from "./Blog/BuildingASuperiorDataDrivenCatModeling";
import PowerofCatModeling from "./Blog/PowerofCatModeling";
import HowAIDrivesInvestmentSuccess from "./Blog/HowAIDrivesInvestmentSuccess";
import InvestmentDecisions from "./Blog/InvestmentDecisions";
import AIandMLInAssetManagement from "./Blog/AIandML_inAssetManagement";
import FutureofWealthManagement from "./Blog/FutureofWealthManagement";
import NavigatingPublicFloat from "./Blog/NavigatingPublicFloat";
import LLMBeyondChatbot from "./Blog/LLM_beyond_chatbot";
import KeyTrendsForAssetManagement from "./Blog/Key_Trends_For_AssetManagement";
import PowerUpYourFinances from "./Blog/Power_Up_Your_Finances";
import EnhancingOperationalEfficiency from "./Blog/Enhancing_Operational_Efficiency";
import SelfCorrectingLLMs from "./Blog/Self_Correcting_LLMs";
import OverviewUSSmallBusinessLoans from "./Blog/Overview_US_Small_BusinessLoans";
import NavigatingCreditRisksWithCreditPulse from "./Blog/NavigatingCreditRisksWithCreditPulse";
import AIinESGAnalysis from "./Blog/AI_in_ESG_Analysis";
import HowAnalyticsIsChanging from "./Blog/How_Analytics_is_Changing";
import SouthAfricasRemarkableRise from "./Blog/SouthAfricasRemarkableRise";
import NavigatingMarketVolatality from "./Blog/NavigatingMarketVolatality";
import SafeguardingDataPrivacyAndSecurity from "./Blog/SafeguardingDataPrivacyAndSecurity";
import NavigatingClimateChange from "./Blog/Navigating_Climate_Change";
import TransformingFinancialServicesWithAI from "./Blog/TransformingFinancialServicesWithAI";
import EnhancingFraudProtectionForAssetManagers from "./Blog/EnhancingFraudProtectionForAssetManagers";
import MLtoDetectFraud from "./Blog/MLtoDetectFraud";
import WhatAreSBALoans from "./Blog/WhatAreSBALoans";
import EmergingTredsInAI from "./Blog/EmergingTredsInAI";
import WhyCreditPulseIsMustHave from "./Blog/WhyCreditPulseIsMustHave";
import PortfolioAllignmentToolsImplementESG from "./Blog/PortfolioAllignmentToolsImplementESG";
import HarnessingGenerativeAI from "./Blog/HarnessingGenerativeAI";
import HowToLeverageDataAnalytics from "./Blog/HowToLeverageDataAnalytics";
import HowAIIsChangingTheGame from "./Blog/HowAIIsChangingTheGame";
import DrivingGrowthAndDecisionMaking from "./Blog/DrivingGrowthAndDecisionMaking";
import DataAutomationWithAISolutions from "./Blog/DataAutomationWithAISolutions";
import DigitalSecurityTokens from "./Blog/Digital_security_tokens";
import RealTimeDataAnalysis from "./Blog/How_ai_is_enabling_real_time_data_analysis";
import RevolutionizingEquityResearchWithAi from "./Blog/Revolutionizing_equity_research_with_ai";
import DataVis from "./Blog/Unlocking_the_potential_of_data_visualization";
import AIMLScope from "./Blog/Scope_of_ai_and_ml_in_finance";
import TrendsInDS from "./Blog/Trends_and_advancement_in_ds";
import BankRunsInBalanceSheet from "./Blog/Bank_runs_in_balance_sheet";
import BuildingProcessEfficiency from "./Blog/BuildingProcessEfficiency";
import AdvancesinAI from "./Blog/Advances_in_ai";
import CautionaryTale from "./Blog/A_cautionary_tale";
import GuidingPolicyMaking from "./Blog/Guiding_policy_making";
import GlobalPendamic from "./Blog/Private_equity_global_pandamic";
import ForgingAhead from "./Blog/Forging_ahead";
import LaplaceDemon from "./Blog/Laplace_demon";
import SocialDistancing from "./Blog/Latrogenesis_of_social_distancing";
import ExtremeFundingStress from "./Blog/Extreme_funding_stress";
import Covid19 from "./Blog/Covid_19";
import ResearchOperations from "./Blog/Research_operations";
import FronUSToYesBank from "./Blog/From_us_to_yes_bank";
import TradeWars from "./Blog/Do_we_really_need_trade_wars";
import IsDemonstrationRespo from "./Blog/Is_demonstration_respo";
import AnalyzingGDPNos from "./Blog/Analyzing_GDP_nos";
import IndiasFiscalContraction from "./Blog/Indias_fiscal_contraction";
import DoesRBINeedToRevise from "./Blog/Does_RBI_need_to_revise";
import IndiaDeflation from "./Blog/India_deflation";
import WhyWillAIEmerge from "./Blog/Why_will_AI_emerge";
import IndiaHeadingTowardDeflation from "./Blog/India_heading_toward_deflation";
import ExcessiveMonetory from "./Blog/Excessive_monetory";
import EmergenceOfSingularity from "./Blog/Emergence_of_singularity";
import TheFallacyOfParadox from "./Blog/The_fallacy_of_paradox";
import AIHeuristicsAndBio from "./Blog/AI_heuristics_and_bio";
import IncentiveStructureForAI from "./Blog/Incentive_structure_for_AI";
import TowardsCollectiveCons from "./Blog/Towards_collective_cons";
import NeedTradeWars2 from "./Blog/Need_trade_wars2";
import TransformYourFinancialOperations from "./Blog/TransformYourFinancialOperations";
import TheGreatSkills from "./Blog/The_great_skills";
import BeginningOfETFShock from "./Blog/Beginning_of_ETF_shock";
import Howa360degreeview from "./Blog/Howa360degreeview";

const Blog_Data = [ 
    {
        link: '/blog/how-a-360-degree-view-of-customer-data-can-help-companies-anticipate-changing-preferences',
        imgsrc: img75,
        heading: "How a 360-degree view of customer data can help companies anticipate changing preferences",
        content: Howa360degreeview
    },
    {
        link: '/blog/what-is-rent-roll-and-outsourcing-for-decision-making',
        imgsrc: img74,
        heading: "Leveraging Artificial Intelligence for Rent Roll Management with Strong Business Processes and Automation",
        content: LeveragingAIforRentRoll
    },
    {
        link: '/blog/transforming-financial-services-the-role-of-ai-and-ml-in-data-automation',
        imgsrc: img73,
        heading: "Transforming Financial Services: The Role of AI and ML in Data Automation",
        content: RoleofAIandML
    },
    {
        link: '/blog/artificial-intelligence-in-finance-transforming-the-future-of-financial-industry',
        imgsrc: img72,
        heading: "Artificial Intelligence in Finance: Transforming the Future of Financial Industry",
        content: AIinFinance
    },
    {
        link: '/blog/top-5-trends-transforming-asset-management',
        imgsrc: img71,
        heading: "Top 5 Trends Transforming Asset Management",
        content: Top5Trends
    },
    {
        link: '/blog/data-driven-catastrophe-modeling',
        imgsrc: img70,
        heading: "Building a superior data-driven catastrophe modeling practice for insurance underwriters",
        content: BuildingASuperiorDataDrivenCatModeling
    },
    {
        link: '/blog/the-power-of-catastrophe-modeling-in-modern-risk-management',
        imgsrc: img69,
        heading: "The Power of Catastrophe Modeling in Modern Risk Management",
        content: PowerofCatModeling
    },
    {
        link: '/blog/how-ai-drives-investment-success-a-game-changer-strategy',
        imgsrc: img68,
        heading: "How AI Drives Investment Success: A Game-Changer Strategy",
        content: HowAIDrivesInvestmentSuccess
    },
    {
        link: '/blog/unlock-alpha-investment-decisions-powered-by-alternative-data',
        imgsrc: img67,
        heading: "Unlock Alpha: Investment Decisions Powered by Alternative Data",
        content: InvestmentDecisions
    },
    {
        link: '/blog/ai-and-machine-learning-in-asset-management-unlocking-hidden-value',
        imgsrc: img66,
        heading: "AI and Machine Learning in Asset Management: Unlocking Hidden Value",
        content: AIandMLInAssetManagement
    },
    {
        link: '/blog/transform-your-financial-operations-with-intelligent-document-processing-idp',
        imgsrc: img65,
        heading: "Transform Your Financial Operations with Intelligent Document Processing (IDP)",
        content: TransformYourFinancialOperations
    },
    {
        link: '/blog/the-future-of-wealth-management-how-ai-is-changing-the-game',
        imgsrc: img64,
        heading: "The Future of Wealth Management: How AI is Changing the Game",
        content: FutureofWealthManagement
    },
    {
        link: '/blog/navigating-public-float-crucial-insights-for-investors',
        imgsrc: img63,
        heading: "Navigating Public Float: Crucial Insights for Investors",
        content: NavigatingPublicFloat
    },
    {
        link: '/blog/llms-beyond-chatbots-exploring-new-frontiers',
        imgsrc: img62,
        heading: "LLMs Beyond Chatbots - Exploring New Frontiers",
        content: LLMBeyondChatbot
    },
    {
        link: '/blog/unlocking-success-key-trends-for-asset-management-in-2024',
        imgsrc: img61,
        heading: "Unlocking Success: Key Trends for Asset Management in 2024",
        content: KeyTrendsForAssetManagement
    },
    {
        link: '/blog/power-up-your-finances-why-migrating-to-power-bi-is-a-game-changer-for-financial-institutions',
        imgsrc: img60,
        heading: "Power Up Your Finances: Why Migrating to Power BI is a Game-Changer for Financial Institutions",
        content: PowerUpYourFinances
    },
    {
        link: '/blog/enhancing-operational-efficiency-applying-operations-management-theories-in-data-analytics-and-research',
        imgsrc: img59,
        heading: "Enhancing Operational Efficiency: Applying Operations Management Theories in Data Analytics and Research",
        content: EnhancingOperationalEfficiency
    },
    {
        link: '/blog/self-correcting-llms-their-potential-to-transform-the-future-of-ai',
        imgsrc: img58,
        heading: "Self-Correcting LLMs: Their Potential to Transform the Future of AI",
        content: SelfCorrectingLLMs
    },
    {
        link: '/blog/overview-us-small-business-loans',
        imgsrc: img57,
        heading: "Overview of US Small Business Loans",
        content: OverviewUSSmallBusinessLoans
    },
    {
        link: '/blog/navigating-credit-risks-with-creditpulse-an-insightful-analysis-for-Q2-2023',
        imgsrc: img56,
        heading: "Navigating Credit Risks with CreditPulse: An Insightful Analysis for Q2 2023",
        content: NavigatingCreditRisksWithCreditPulse
    },
    {
        link: '/blog/ai-in-esg-analysis-how-ai-is-transforming-the-way-we-assess-environmental-social-and-governance-factors',
        imgsrc: img55,
        heading: "AI in ESG Analysis: How AI is Transforming the Way We Assess Environmental, Social, and Governance Factors",
        content: AIinESGAnalysis
    },
    {
        link: '/blog/how-analytics-is-changing-the-face-of-personalization-in-finance',
        imgsrc: img54,
        heading: "How Analytics is Changing the Face of Personalization in Finance",
        content: HowAnalyticsIsChanging
    },
    {
        link: '/blog/south-africas-remarkable-rise-in-responsible-investing-insights-from-dpa-south-africa-esg-review-2023',
        imgsrc: img53,
        heading: "South Africa's Remarkable Rise in Responsible Investing: Insights from DPA South Africa ESG Review 2023",
        content: SouthAfricasRemarkableRise
    },
    {
        link: '/blog/navigating-market-volatility-how-data-analytics-empowers-asset-managers',
        imgsrc: img52,
        heading: "Navigating Market Volatility: How Data Analytics Empowers Asset Managers",
        content: NavigatingMarketVolatality
    },
    {
        link: '/blog/safeguarding-data-privacy-and-security-in-financial-analytics-best-practices-and-technologies',
        imgsrc: img51,
        heading: "Safeguarding Data Privacy and Security in Financial Analytics: Best Practices and Technologies",
        content: SafeguardingDataPrivacyAndSecurity
    },
    {
        link: '/blog/navigating-climate-change-how-the-financial-services-industry-can-adapt-and-thrive',
        imgsrc: img50,
        heading: "Navigating Climate Change: How the Financial Services Industry Can Adapt and Thrive",
        content: NavigatingClimateChange
    },
    {
        link: '/blog/transforming-financial-services-with-ai-reshaping-risk-management-personalizing-experiences-and-strengthening-fraud-detection',
        imgsrc: img49,
        heading: "Transforming Financial Services with AI: Reshaping Risk Management, Personalizing Experiences, and Strengthening Fraud Detection",
        content: TransformingFinancialServicesWithAI
    },
    {
        link: '/blog/enhancing-fraud-protection-for-asset-managers-through-effective-data-management',
        imgsrc: img48,
        heading: "Enhancing Fraud Protection for Asset Managers through Effective Data Management",
        content: EnhancingFraudProtectionForAssetManagers
    },
    {
        link: '/blog/using-machine-learning-to-detect-fraud-a-step-forward-in-financial-security',
        imgsrc: img47,
        heading: "Using Machine Learning to Detect Fraud: A Step Forward in Financial Security",
        content: MLtoDetectFraud
    },
    {
        link: '/blog/what-are-small-business-administration-sba-loans',
        imgsrc: img46,
        heading: "What are Small Business Administration (SBA) Loans?",
        content: WhatAreSBALoans
    },
    {
        link: '/blog/emerging-trends-in-artificial-intelligence-ai-and-machine-learning-ml-whats-next-for-financial-services',
        imgsrc: img45,
        heading: "Emerging Trends in Artificial Intelligence (AI) and Machine Learning (ML): What's Next for Financial Services?",
        content: EmergingTredsInAI
    },
    {
        link: '/blog/why-creditpulse-is-a-must-have-for-risk-management-professionals',
        imgsrc: img44,
        heading: "Why CreditPulse is a Must-Have for Risk Management Professionals?",
        content: WhyCreditPulseIsMustHave
    },
    {
        link: '/blog/how-portfolio-alignment-tools-implement-esg-strategies-for-investors',
        imgsrc: img43,
        heading: "How Portfolio Alignment Tools Implement ESG Strategies for Investors",
        content: PortfolioAllignmentToolsImplementESG
    },
    {
        link: '/blog/harnessing-generative-ai-for-innovative-saas-products',
        imgsrc: img42,
        heading: "Harnessing Generative AI (Artificial Intelligence) for Innovative SaaS Products",
        content: HarnessingGenerativeAI
    },
    {
        link: '/blog/how-to-leverage-data-analytics-for-improved-investment-decision-making',
        imgsrc: img41,
        heading: "How to Leverage Data Analytics for Improved Investment Decision Making",
        content: HowToLeverageDataAnalytics
    },
    {
        link: '/blog/how-artificial-intelligence-is-changing-the-game-in-finance',
        imgsrc: img40,
        heading: "How Artificial Intelligence is Changing the Game in Finance",
        content: HowAIIsChangingTheGame
    },
    {
        link: '/blog/driving-growth-and-decision-making-unleashing-the-power-of-financial-business-intelligence',
        imgsrc: img39,
        heading: "Driving Growth and Decision-Making: Unleashing the Power of Financial Business Intelligence",
        content: DrivingGrowthAndDecisionMaking
    },
    {
        link: '/blog/data-automation-optimizing-financial-operations-with-ai-driven-solutions',
        imgsrc: img38,
        heading: "Data Automation: Optimizing Financial Operations with AI-Driven Solutions",
        content: DataAutomationWithAISolutions
    },
    {
        link: '/blog/digital-security-tokens-a-better-way-of-raising-capital',
        imgsrc: img37,
        heading: "Digital Security Tokens – A Better Way of Raising Capital",
        content: DigitalSecurityTokens
    },
    {
        link: '/blog/how-ai-is-enabling-real-time-data-analysis-for-businesses',
        imgsrc: img36,
        heading: "How AI is Enabling Real-Time Data Analysis for Businesses",
        content: RealTimeDataAnalysis
    },
    {
        link: '/blog/revolutionizing-equity-research-with-artificial-intelligence-the-future-is-here',
        imgsrc: img35,
        heading: "Revolutionizing Equity Research with Artificial Intelligence: The Future is Here",
        content: RevolutionizingEquityResearchWithAi
    },
    {
        link: '/blog/unlocking-the-potential-of-data-visualization-the-key-to-business-growth-and-optimization',
        imgsrc: img34,
        heading: "Unlocking the Potential of Data Visualization: The Key to Business Growth and Optimization",
        content: DataVis
    },
    {
        link: '/blog/scope-of-ai-and-ml-in-finance',
        imgsrc: img33,
        heading: "Scope of AI & ML in Finance",
        content: AIMLScope
    },
    {
        link: '/blog/trends-and-advancement-in-data-science',
        imgsrc: img31,
        heading: "Trends And Advancements In Data Science And AI In 2023",
        content: TrendsInDS
    },
    {
        link: '/blog/bank-runs-in-balancesheet',
        imgsrc: img30,
        heading: "How to Value “Bank Runs” in Balance Sheet?",
        content: BankRunsInBalanceSheet
    },
    {
        link: '/blog/building-process-efficiency',
        imgsrc: img29,
        heading: "Building process efficiency for US Lenders to offer ERC/SBA loans",
        content: BuildingProcessEfficiency
    },
    {
        link: '/blog/advances-in-ai',
        imgsrc: img28,
        heading: "Advances in AI – An opportunity for India’s IT/ITES sector to advance in productivity.",
        content: AdvancesinAI
    },
    {
        link: '/blog/the-great-skills',
        imgsrc: img27,
        heading: "The Great Skills Shortage and BhagvatGita",
        content: TheGreatSkills
    },
    {
        link: '/blog/a-cautionary-tale',
        imgsrc: img26,
        heading: "A Cautionary Tale for Bitcoin Maximalists",
        content: CautionaryTale
    },
    {
        link: '/blog/guiding-policy-making',
        imgsrc: img25,
        heading: "Guiding Policymaking through the Fog of Covid19 induced Lockdowns",
        content: GuidingPolicyMaking
    },
    {
        link: '/blog/private-equity-global-pandamic',
        imgsrc: img24,
        heading: "Private Equity - Navigating the Global Pandemic Environment",
        content: GlobalPendamic
    },
    {
        link: '/blog/forging-ahead',
        imgsrc: img23,
        heading: "Forging Ahead in Troubled Times",
        content: ForgingAhead
    },
    {
        link: '/blog/laplace-demon',
        imgsrc: img22,
        heading: "Laplace’s Demon and Limits to Central Banks’ Ability to Stabilize Economy",
        content: LaplaceDemon
    },
    {
        link: '/blog/latrogenesis-of-social-distancing',
        imgsrc: img21,
        heading: "Iatrogenesis of Social Distancing: Thoughts on Flaws in Deeper Structure of Society.",
        content: SocialDistancing
    },
    {
        link: '/blog/beginning-of-etf-shock',
        imgsrc: img20,
        heading: "Beginning of ETF Shock",
        content: BeginningOfETFShock
    },
    {
        link: '/blog/extreme-funding-stress',
        imgsrc: img19,
        heading: "Extreme Funding Stress in Dollar Market",
        content: ExtremeFundingStress
    },
    {
        link: '/blog/covid-19',
        imgsrc: img18,
        heading: "COVID-19 and folly of ETFs",
        content: Covid19
    },
    {
        link: '/blog/research-operations',
        imgsrc: img17,
        heading: "Customized, Optimized and Enhanced research operations",
        content: ResearchOperations
    },
    {
        link: '/blog/from-us-to-yes-bank',
        imgsrc: img16,
        heading: "From Bank of United States to Yes Bank: Why Bank Stocks should be ineligible as loan security",
        content: FronUSToYesBank
    },
    {
        link: '/blog/do-we-really-need-trade-wars',
        imgsrc: img15,
        heading: "Do we really need trade wars to shrink international trade-flows in Physical Goods?",
        content: TradeWars
    },
    {
        link: '/blog/is-demonstration-respo',
        imgsrc: img14,
        heading: "Is Demonetization Responsible for Lower GDP Growth Rate?",
        content: IsDemonstrationRespo
    },
    {
        link: '/blog/analyzing-gdp-nos',
        imgsrc: img13,
        heading: "Analyzing The GDP numbers",
        content: AnalyzingGDPNos
    },
    {
        link: '/blog/indias-fiscal-contraction',
        imgsrc: img12,
        heading: "India’s coming fiscal contraction – effect on growth and inflation",
        content: IndiasFiscalContraction
    },
    {
        link: '/blog/does-rbi-need-to-revise',
        imgsrc: img11,
        heading: "Does RBI need to revise its models?",
        content: DoesRBINeedToRevise
    },
    {
        link: '/blog/india-deflation',
        imgsrc: img10,
        heading: "India Deflation: Strategic, Tactical and Operational Options for RBI",
        content: IndiaDeflation
    },
    {
        link: '/blog/why-will-ai-emerge',
        imgsrc: img9,
        heading: "Why Will AI Emerge Spontaneously?",
        content: WhyWillAIEmerge
    },
    {
        link: '/blog/india-heading-toward-deflation',
        imgsrc: img8,
        heading: "India Heading Towards Deflation",
        content: IndiaHeadingTowardDeflation
    },
    {
        link: '/blog/excessive-monetory',
        imgsrc: img7,
        heading: "Excessive Monetary Tightening in India as compared to OECD",
        content: ExcessiveMonetory
    },
    {
        link: '/blog/emergence-of-singularity',
        imgsrc: img6,
        heading: "Emergence of Singularity: Building Anti Fragile Human Race",
        content: EmergenceOfSingularity
    },
    {
        link: '/blog/the-fallacy-of-paradox',
        imgsrc: img5,
        heading: "The Fallacy of the Repugnant Paradox",
        content: TheFallacyOfParadox
    },
    {
        link: '/blog/ai-heuristics-and-bio',
        imgsrc: img4,
        heading: "Artificial Intelligence, Heuristics and Biomimicry",
        content: AIHeuristicsAndBio
    },
    {
        link: '/blog/incentive-structure-for-ai',
        imgsrc: img3,
        heading: "Incentive Structure for building Artificial Intelligence with a sense of morality",
        content: IncentiveStructureForAI
    },
    {
        link: '/blog/towards-collective-cons',
        imgsrc: img2,
        heading: "Towards Collective Consciousness of Human Race",
        content: TowardsCollectiveCons
    },
    {
        link: '/blog/need-trade-wars-two',
        imgsrc: img1,
        heading: "Do we really need trade wars to shrink international trade-flows in Physical Goods?",
        content: NeedTradeWars2
    }
]

export default Blog_Data;