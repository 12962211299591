import { Container, Grid, Typography, Box, Button, } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import MailOutlineIcon from "@material-ui/icons/MailOutline";

import Herobg from "../../asset/images/crm/hero_form_bg.webp";

import optimizeimg from "../../asset/images/crm/fin_services.webp";
import CRMHeroForm from '../../Component/forms/CRMHeroForm';
import EastIcon from '@mui/icons-material/East';
import { CRMComprehensive } from './CRMComprehensive';
import { ToolsTechnology } from './ToolsTechnology';
import { WhyChoose } from './WhyChoose';
import { CRMSuccessStories } from './CRMSuccessStories';
import CRMFooterForm from '../../Component/forms/CRMFooterForm';
import CRMStreamlineForm from '../../Component/forms/CRMStreamlineForm';
import LetsConnectCTA from '../../Component/forms/LetsConnectCTA';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default function CrmHero() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    React.useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                const offset = 170;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - offset;
                window.scrollBy({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }
    }, []);

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const stickyItemsHeight = 70;
            const offset = element.getBoundingClientRect().top - stickyItemsHeight;


            setTimeout(() => {
                window.scrollBy({ top: offset, behavior: 'smooth' });
            }, 50);
        }
    };

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title>Maximize Your Salesforce CRM Potential | DPA Salesforce Support Solutions</title>
                <meta name="description" content="Unlock the full potential of your Salesforce CRM with DPA's certified experts. Our tailored solutions optimize efficiency, streamline processes, and enhance client engagement." />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/crm" />
                <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <Box id='crmpage' >

                <Box id='crmherosection'>
                    <Box className="mainsection1" component="section" style={{ backgroundImage: "url(" + Herobg + ")" }} py={2} id='crm'>
                        <Box className='section'>
                            <Container maxWidth="lg">
                                <Grid container spacing={1} alignItems="center" justifyContent='center'>
                                    <Grid item lg={7} md={6} sm={11} xs={10}>
                                        <Box className="text_animated">
                                            <Typography variant="h2" className="skblue fw7 mb0" gutterBottom>
                                                maximize your CRM's efficiency
                                            </Typography>

                                            <Box>
                                                <Typography variant="h2" className="white" gutterBottom>
                                                    with DPA's expert support to streamline operations and enhance client engagement.
                                                </Typography>
                                            </Box>

                                            <Box mt={2}>
                                                <a href="mailto:info@decimalpointanalytics.com" className='fitwidth'>
                                                    <Button variant="text" className="txt_btn white flx_left" disableRipple startIcon={<MailOutlineIcon />} size="medium">
                                                        info@decimalpointanalytics.com
                                                    </Button>
                                                </a>

                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={5} md={6} sm={12} xs={12}>
                                        <Box>
                                            <CRMHeroForm />
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Container>
                        </Box>
                    </Box>
                </Box>

                <Box id='aboutus'>

                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="flex-start" spacing={0}>
                        <Grid item lg={6} md={6} sm={12} xs={12} >
                            <Box>
                                <img src={optimizeimg} className="topbanner2" alt="Optimize" />
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} >
                            <Container maxWidth='md' className='bdr'>
                                <Box className="content section">
                                    <Grid container direction="row" justifyContent="start" alignItems="flex-start" >
                                        <Grid item lg={8} md={12} sm={12} xs={12} >
                                            <Typography variant="h2" className="black fw5 mob_c">
                                                Optimize your CRM for <span className='blue'> Financial Services</span>
                                            </Typography>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12} xs={12} >
                                            <Typography variant="h6" className="black jst">
                                                At Decimal Point Analytics (DPA), we understand the pivotal role a proficient CRM system plays in managing business pipelines and nurturing client relationships within the financial sector. Salesforce CRM, equipped with advanced features, can be mastered to its fullest capacity with our specialized guidance. Our Salesforce CRM Support Solutions are meticulously designed to help you seamlessly integrate, manage, and boldly leverage Salesforce CRM. We ensure your financial services firm—be it asset management, private equity, or venture capital—achieves superior operational efficiency and elevated client engagement.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Box>

                                <Box className='mob_c' pb={2}>

                                    {/* <LetsConnectCTA className="dpabtn_1 " btn_name="Contact Us" endIco={<EastIcon className='arrow2' />} /> */}

                                    <Link to="/crm#crmfooterform" onClick={() => scrollToElement('crmfooterform')}>
                                        <Button
                                            variant="contained"
                                            className="dpabtn_1"
                                            endIcon={<EastIcon className='arrow2' />}
                                        >
                                            Contact Us
                                        </Button>
                                    </Link>
                                </Box>
                            </Container>
                        </Grid>


                    </Grid>
                </Box>

                <Box id='solution' component="section">
                    <CRMComprehensive />
                </Box>

                <CRMStreamlineForm />


                <Box id='whychoose' className='section2'>
                    <ToolsTechnology />
                </Box>

                <WhyChoose />

                <Box id='success_stories'>
                    <CRMSuccessStories />
                </Box>


                <Box id='crmfooterform'>
                    <CRMFooterForm />
                </Box>
            </Box>

        </>
    );
}
