import SP from "../../asset/images/insight/podcast_new/sanika_pandit.webp"
import SD from "../../asset/images/insight/podcast_new/shailesh_dhuri.webp"
import AS from "../../asset/images/insight/podcast_new/arun_singh.webp"
import AJ from "../../asset/images/insight/podcast_new/ajay_jindal.webp"
import SS from "../../asset/images/insight/podcast_new/suyash_shrivastava.webp"
import PS from "../../asset/images/insight/podcast_new/pravas_kumar_sahoo.webp"
import AD from "../../asset/images/insight/podcast_new/amit_dhalia.webp"
import PN from "../../asset/images/insight/podcast_new/prasad_nawathe.webp"
import DR from "../../asset/images/insight/podcast_new/david_riedel.webp"
import DP from "../../asset/images/insight/podcast_new/darshan_potade.webp"
import SB from "../../asset/images/insight/podcast_new/suvidha_bhandary.webp"
import ND from "../../asset/images/insight/podcast_new/nilanjan_das.webp"
import SV from "../../asset/images/insight/podcast_new/shubhendu_verma.webp"
import SW from "../../asset/images/insight/podcast_new/shreekant_wable.webp"
import PB from "../../asset/images/insight/podcast_new/pushkaraj_behere.webp"
import GG from "../../asset/images/insight/podcast_new/gaurav_gupta.webp"

const Podcast_Data = [
    {
        link: "https://www.youtube.com/watch?v=2JHxnD7DUX0&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB",
        imgsrc: SW,
        heading: "Exploring Equity Markets and the Impact of AI on Research & Investment Strategies",
        date: "Aug 31, 2024",
        subheading: "Shreekant covers global and Indian markets, AI's influence on equity research, and fostering innovation for deeper analysis.",
    },
    {
        link: "https://www.youtube.com/watch?v=MKdenjzeWk0&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=2",
        imgsrc: ND,
        heading: "Maximing succes in SBA Lending:Comprehensive strategic insights",
        date: "Aug 09, 2024",
        subheading: "Nilanjan discusses SBA lending, strategic support for lenders, and the growth opportunities in this competitive market.",
    },
    {
        link: "https://www.youtube.com/watch?v=paI241kKWEM&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=5",
        imgsrc: PB,
        heading: "Streamlining RFP & DDQ Management for Global Fund Managers",
        date: "Jun 26, 2024",
        subheading: "Pushkaraj discusses strategies, automation, and best practices in RFP and DDQ management for asset managers.",
    },
    {
        link: "https://www.youtube.com/watch?v=exWEn6MR36k",
        imgsrc: SB,
        heading: "Leveraging Salesforce Automation for Financial Reporting & Analysis",
        date: "May 31, 2024",
        subheading: "Suvidha shares how Salesforce enhances client productivity with tailored solutions and expert integration.",
    },
    {
        link: "https://www.youtube.com/watch?v=X4DCAKyLuEY",
        imgsrc: DP,
        heading: "SBA Loan Processing: Unlocking Efficiency through Streamlined Operations for Increased Productivity",
        date: "Apr 24, 2024",
        subheading: "Darshan discusses enhancing SBA loan processing efficiency for US clients, reducing costs and improving turnaround.",
    },
    {
        link: "https://www.youtube.com/watch?v=eSfGBubb7gk&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=3",
        imgsrc: SP,
        heading: "Transforming PDF Interactions with Sanika Pandit",
        date: "Apr 05, 2024",
        subheading: "Sanika presents PDF Query, an AI tool for instant data extraction from PDFs, simplifying tasks for various professionals.",
    },
    {
        link: "https://www.youtube.com/watch?v=Tyyvw4WAdjo&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=9",
        imgsrc: GG,
        heading: "Key Trends for Asset Management In 2024",
        date: "Mar 20, 2024",
        subheading: "Gaurav Gupta, our Managing Partner, explores key trends for asset managers in 2024, highlighting essential trajectories and offering valuable insights to navigate the complexities ahead.",
    },
    {
        link: "https://open.spotify.com/episode/0YWQLh7HA7z8QvV5AbAvTm",
        imgsrc: SD,
        heading: "CEO Shailesh Dhuri in an Exclusive Podcast with Entrepreneur Lounge of India",
        date: "Mar 13, 2024",
        subheading: "Shailesh shares his journey of scaling a company, highlighting flexibility, adaptability, and building a strong culture.",
    },
    {
        link: "https://www.youtube.com/watch?v=CtZGDkqROCY&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjI",
        imgsrc: AJ,
        heading: "Unlocking Success: 2024's Top Five ESG Trends for Asset Managers",
        date: "Feb 23, 2024",
        subheading: "Ajay analyzes key 2024 ESG trends, from stricter regulations to data-driven decisions, reshaping asset management.",
    },
    {
        link: "https://www.youtube.com/watch?v=L_W3oLcUQw8",
        imgsrc: DR,
        heading: "Navigating Challenges for Small Businesses in 2024: Rising Interest Rates",
        date: "Feb 13, 2024",
        subheading: "David shares strategies for small businesses to manage rising interest rates and thrive amid financial challenges.",
    },
    {
        link: "https://www.youtube.com/watch?v=3E-QBcE2GI0",
        imgsrc: SV,
        heading: "The Evolution of APIs: How They Work and Why Are They Important?",
        date: "Dec 04, 2023",
        subheading: "Shubhendu explains APIs, their evolution, and their role in enabling seamless communication between applications.",
    },
    {
        link: "https://www.youtube.com/watch?v=3Pu2weDavYE",
        imgsrc: AJ,
        heading: "DPA USA ESG Review 2023: USA's Path to Net-Zero and Responsible Investing",
        date: "May 30, 2023",
        subheading: "Ajay discusses US ESG trends, highlighting gaps in Net-Zero commitments and opportunities for responsible investing.",
    },
    {
        link: "https://www.youtube.com/watch?v=Dtuna7KnjUQ",
        imgsrc: PN,
        heading: "Navigating the ESG Landscape: Insights from Prasad Nawathe | Decimal Point Analytics Unplugged",
        date: "Oct 18, 2023",
        subheading: "Prasad shares data-driven strategies for ESG investing, focusing on analytics, innovation, and impactful decisions.",
    },
    {
        link: "https://youtu.be/GcI0eq-OQHs?si=yQjQx3W__VPI-Clz",
        imgsrc: AD,
        heading: "How Business Intelligence is Changing Businesses in Today's Ever-Changing",
        date: "Oct 13, 2023",
        subheading: "Amit explores modern Business Intelligence, highlighting its role in enhancing decision-making through real-time data.",
    },
    {
        link: "https://www.youtube.com/watch?v=tg7y2BCDqdM",
        imgsrc: PS,
        heading: "Strategic Insights into Finance & Innovation with Pravas Sahoo",
        date: "Sep 25, 2023",
        subheading: "Pravas discusses valuation techniques, the power of statistics, and how AI is shaping the future of financial research.",
    },
    {
        link: "https://open.spotify.com/episode/3hBMTuRrENqKYBeYz3pcnG?si=PPONZwUiSie6Futo1XsBIg&nd=1",
        imgsrc: AJ,
        heading: "Ajay Jindal in Conversation with CRTA on Canada ESG Review 2023",
        date: "Sep 20, 2023",
        subheading: "Ajay discusses Canada's ESG progress, highlighting the gap between advancements in asset management and the real economy, as explored in his recent paper, 'Canada ESG Review 2023.'",
    },
    {
        link: "https://www.youtube.com/watch?v=xgVNbqLzm7E&list=PLG-WwrL93I2_GuoPBNWMSazmqtCrYIjIB&index=17",
        imgsrc: GG,
        heading: "Creating Efficiencies with Technology",
        date: "Aug 14, 2023",
        subheading: "Gaurav discusses boosting efficiency with tech, revealing how to harness technology for operational effectiveness, data-informed decisions, and smooth collaboration.",
    },
    {
        link: "https://www.youtube.com/watch?v=wsSgZ22V-Tw",
        imgsrc: AJ,
        heading: "Exploring Canada’s ESG Landscape: Insights from DPA’s ESG Review",
        date: "Aug 03, 2023",
        subheading: "Ajay and Bishwadeep discuss Canada's ESG progress, highlighting strengths and gaps in Net-Zero commitments.",
    },
    {
        link: "https://www.youtube.com/watch?v=WRyLIRguWMw&t=2s",
        imgsrc: SS,
        heading: "Data warehousing Cloud and Beyond: Exploring the Future with Suyash",
        date: "Jul 11, 2023",
        subheading: "Suyash explores cloud data warehousing benefits, focusing on scalability, cost efficiency, and enhanced security.",
    },
    {
        link: "https://www.youtube.com/watch?v=FDO5ldmEfX4",
        imgsrc: SD,
        heading: "How will GPTs change the Analytics Landscape?",
        date: "Jul 17, 2023",
        subheading: "Shailesh discusses GPTs' transformative impact on analytics and their broader implications across various industries.",
    },
    {
        link: "https://podcasters.spotify.com/pod/show/canadianregtech/episodes/Is-Machine-Readable-Regulation-the-Answer-to-Regulatory-Burden-e25ej7v/a-a9vh8gh",
        imgsrc: SD,
        heading: "Is Machine Readable Regulation the Answer to Regulatory Burden?",
        date: "Jun 08, 2023",
        subheading: "Shailesh explores how Machine-Readable Regulation can enhance compliance, cut costs, and reshape the regulatory field.",
    },
    {
        link: "https://www.youtube.com/watch?v=iTmO6qbip9Q",
        imgsrc: AJ,
        heading: "Exploring the ESG landscape and Net Zero possibilities!",
        date: "May 30, 2023",
        subheading: "Ajay highlights ESG solutions, emphasizing Net-Zero as a priority for sustainable investment strategies.",
    },
    {
        link: "https://youtu.be/M_-gONwkGbY",
        imgsrc: AS,
        heading: "A Zero possibilities! HR Insights: Expert Analysis & Trends",
        date: "May 19, 2023",
        subheading: "Arun dives into HR analytics, discussing data-driven strategies for optimizing recruitment, engagement, and diversity.",
    },
    {
        link: "https://www.youtube.com/watch?v=49h8lRYKCis",
        imgsrc: SD,
        heading: "CEO Shailesh Dhuri in an Exclusive interview with Start-up Story Media",
        date: "Nov 21, 2022",
        subheading: "Shailesh shares his entrepreneurial journey and insights on emerging concepts and trends in the fintech industry in an interview with Startup Story Media.",
    },
];


export default Podcast_Data;