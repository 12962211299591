import { Container, Grid, Typography, Box,Tab ,Tabs,AppBar } from '@material-ui/core';
import React, { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Helmet } from 'react-helmet';

// import ESGNews from "../../asset/images/esg/esg_equity/esg_equity_banner.webp"

import ProductCTAA from '../../Component/ProductCTAA';
import whyCP from "../../asset/images/product/creditpulse/why_credit_pulse.webp"

import feature1 from "../../asset/images/esg/esg_equity/features/feature1.webp";
import feature2 from "../../asset/images/esg/esg_equity/features/feature2.webp";
import feature3 from "../../asset/images/esg/esg_equity/features/feature3.webp";
import feature4 from "../../asset/images/esg/esg_equity/features/feature4.webp";
import feature5 from "../../asset/images/esg/esg_equity/features/feature5.webp";
import feature6 from "../../asset/images/esg/esg_equity/features/feature6.webp";

import solution1 from "../../asset/images/esg/esg_equity/solutions/solution1.webp";
import solution2 from "../../asset/images/esg/esg_equity/solutions/solution2.webp";
import solution3 from "../../asset/images/esg/esg_equity/solutions/solution3.webp";
import solution4 from "../../asset/images/esg/esg_equity/solutions/solution4.webp";
import { Link } from 'react-router-dom';

import banenr2 from "../../asset/images/esg/esg_equity/feature.webp";


export default function ESGPrivateEquity() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

        // main tabs
        const [valueESG, setValueESG] = React.useState(3);

        const handleChangeESG = (event, newValue) => {
            window.scrollTo(0, 0);
            setValueESG(newValue);
        };
    
        const [value, setValue] = React.useState(1);
    
        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

    return (
        <>

            <Helmet>
                <meta charset="UTF-8" />
                <title> ESG Solutions for PE Funds </title>
                <meta name="description" content="Optimize ESG strategies for PE with our tailored solutions" />
                <meta name="keywords" content="Private Equity ESG Excellence Suite, ESG integration, Sustainable growth, ESG reporting solutions, Compliance monitoring, Data warehousing, Real-time data visualization, Compliance tracking, Dynamic dashboards" />
                <meta name="author" content="Decimal Point Analytics" />
                <link rel="canonical" href="https://www.decimalpointanalytics.com/elevating-private-equity-performance-with-strategic-esg-solutions" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>


            {/* <Box className="banner_bx">
                <Box style={{ backgroundImage: "url(" + ESGNews + ")" }} className="banner_img">
                    <Box className="img_over bl_overlay">
                        <Grid container spacing={0} alignItems="center" justifyContent='center'>
                            <Grid item lg={5} md={7} sm={10} xs={10}>
                                <Box className="al_center">
                                    <Typography className="white" variant="h1">Private Equity ESG Excellence Suite</Typography>
                                    <Box mt={'10px'}>
                                        <Typography className="white" variant="h4">Elevating Private Equity Performance with Strategic ESG Solutions
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box> */}

<Box id="esg" className="stiky3">
                <Box className="maintabbedsection linkedtabs">
                    <Container maxWidth="lg" className="bdr">
                        <Grid container spacing={1} alignItems="top" justifyContent="center">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <AppBar position="static" className="tabbedbar dgrid_center">
                                    <Tabs value={valueESG} onChange={handleChangeESG} variant="scrollable">
                                        <Tab
                                            label="ESG"
                                            component={Link}
                                            to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses"
                                            value={0}
                                        />
                                        <Tab
                                            label="ESG Insights"
                                            component={Link}
                                            to="/esg-insights/esg-whitepaper"
                                            value={1}
                                        />
                                        <Tab
                                            label="ESG Roundtable"
                                            component={Link}
                                            to="/environmental-social-and-governance-for-financial-institutions-and-enterprise-businesses#esg-executable-roundtable"
                                            value={2}
                                        />
                                        <Tab
                                            label="ESG Products"
                                            component={Link}
                                            to="/empowering-esg-investment-with-real-time-analytics"
                                            value={3}
                                        />
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>


            <Box className="maintabbedsection stiky4 linkedtabs">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={1} alignItems="top" >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <AppBar position="static" className="tabbedbar dgrid_center">
                                <Tabs value={value} onChange={handleChange} variant="scrollable">
                                    <Tab
                                        label="News Senti-Meter"
                                        component={Link}
                                        to="/empowering-esg-investment-with-real-time-analytics"
                                        value={0}
                                    />
                                    <Tab
                                        label="Private Equity"
                                        component={Link}
                                        to="/elevating-private-equity-performance-with-strategic-esg-solutions"
                                        value={1}
                                    />
                                    <Tab
                                        label="Greenlnvest Pulse"
                                        component={Link}
                                        to="/align-your-investments-with-global-sustainability-goals"
                                        value={2}
                                    />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className='section2 sec_bg' component='section' mt={4}>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center synopsic_bx">
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Accelerate Sustainable Growth <span className='lower'> with</span> Comprehensive ESG Integration
                                    </Typography>
                                </Box>
                                <Typography variant='h6' className='black jst' gutterBottom>
                                    Discover the power of the Private Equity ESG Excellence Suite from Decimal Point Analytics, specifically designed for private equity and alternative asset funds. In today's regulatory and investment environment, mastering Environmental, Social, and Governance (ESG) principles is crucial. Our advanced SaaS-based solution leverages the latest in ESG frameworks with data management and automation technologies, seamlessly integrating ESG into your investment strategies and to enhance value and mitigate risks.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_content="Transform Your ESG Strategy Today " cta_btn_name="Contact Us" pageVisited="Private Equity ESG Excellence Suite" />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className='section mob_card' component='section'>
                <Container maxWidth='lg' className='bdr'>
                    <Grid container spacing={2} alignItems="center" justifyContent='center'>

                        <Grid container spacing={1} alignItems="top">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='al_center'>
                                    <Typography variant='h2' className='black'>
                                        Our Solutions
                                        <Typography variant='h2' className='black h2v2'>
                                            Enhanced ESG Reporting Solutions <span className='lower'>for</span> Simplified Compliance
                                        </Typography>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg3'>
                                <Box className='al_center'>
                                    <img src={solution1} className='allic' alt='OP1' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={8} sm={12} xs={12}>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Advanced Data Collection
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Use our flexible templates to efficiently collect detailed ESG data, customized for industry and region.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg3'>
                                <Box className='al_center'>
                                    <img src={solution2} className='allic' alt='OP2' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={8} sm={12} xs={12}>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Unified Data Warehousing
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Achieve consistent data analysis with our secure, cloud-based storage solutions.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg3'>
                                <Box className='al_center'>
                                    <img src={solution3} className='allic' alt='OP3' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={8} md={9} sm={12} xs={12}>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Dynamic Insights via Dashboards
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Gain actionable insights with advanced real-time visualization and trend analysis.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box className='boxbg mnhg3'>
                                <Box className='al_center'>
                                    <img src={solution4} className='allic' alt='OP4' />
                                </Box>
                                <Box>
                                    <Grid container spacing={1} alignItems="top" justifyContent='center'>
                                        <Grid item lg={9} md={12} sm={12} xs={12}>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Comprehensive Compliance Monitoring
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='h6' className='black al_center mb0'>
                                        Stay compliant with our robust tracking system, offering instant updates on essential policies and standards.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className='section mob_card sec_bg' component='section'>
                <Container maxWidth='lg' className='bdr' style={{ position: 'relative', }}>

                    <Grid container spacing={1} alignItems="top">
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='al_center'>
                                <Typography variant='h2' className='black'>
                                    Key Features <span className='lower'> of the</span> Private Equity ESG Excellence Suite
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} alignItems="center">

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img src={feature1} className='allic' alt='SA1' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Flexible Data Handling
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Leverage our versatile data gathering templates and efficient middleware to manage a diverse range of data types for seamless integration and reporting.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img src={feature3} className='allic' alt='SA3' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Deployment Flexibility
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                User access via Customizable microsite with separate logins for portfolio companies, GP, and LPs, with data hosting on our secure DPA cloud or client’s cloud.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img src={feature5} className='allic' alt='SA5' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Thorough Pre-Investment Assessments
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Conduct in-depth ESG due diligence to align potential investments with sector-specific criteria, enhancing strategic investment decisions.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className='al_center'>
                                <img src={banenr2} className='topbanner2' alt='Banner2' />
                            </Box>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img src={feature2} className='allic' alt='SA2' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Dynamic Dashboards
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Flexible dashboards provide differentiated features tailored specifically for portfolio companies, general partners, and limited partners, enhancing decision-making and functionality.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img src={feature4} className='allic' alt='SA4' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Comprehensive Reporting Tools
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Generate precise and compliant sustainability reports tailored to specific frameworks like TCFD, GRI, SASB; our solution is aligned with EDCI.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box className='boxbg mnhg2'>
                                        <Box className='al_center'>
                                            <img src={feature6} className='allic' alt='SA6' />
                                        </Box>
                                        <Box>
                                            <Typography variant='h6' className='blue al_center fw6'>
                                                Compliance Assurance
                                            </Typography>
                                            <Typography variant='h6' className='black al_center mb0'>
                                                Makes third party assurance conclusions easier by providing track and trace to reported raw data, reinforcing stakeholder confidence.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_btn_name="Schedule a Demo" pageVisited="Private Equity ESG Excellence Suite" />
                            </Box>
                        </Grid>


                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className="section" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={0} alignItems="top" justifyContent='center'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="al_center">
                                <Typography className="black" variant="h2">
                                    Why Partner with DPA?
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={5} md={5} sm={6} xs={12}>
                            <Box className='al_center'>
                                <img src={whyCP} className='topbanner5' alt='Why Partner with DPA?' />
                            </Box>
                        </Grid>

                        <Grid item lg={7} md={7} sm={6} xs={12}>
                            <Box>
                                <Typography className="blue" variant="h6">
                                    <ul>
                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Tailored Integration and Reporting Platform: </strong> Custom-crafted for private equity, our platform accommodates various user roles efficiently and intuitively.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Optimized ESG Processes: </strong> From data collection to reporting, our streamlined processes enhance efficiency and foster superior ESG practices.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography variant="h6" className="black jst" gutterbottom>
                                                <strong>Unmatched Expertise and Support: </strong> Our team offers strategic insights and detailed guidance on ESG regulations, ensuring robust and future-proof investment strategies.
                                            </Typography>
                                        </li>

                                    </ul>
                                </Typography>
                            </Box>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box className='flx_fs mob_center'>
                                    <ProductCTAA allignment="flx_fs" cta_content="Uncover the DPA Difference in ESG Management" cta_btn_name="Contact Us" pageVisited="Private Equity ESG Excellence Suite" />
                                </Box>
                            </Grid>

                        </Grid>


                    </Grid>
                </Container>
            </Box>

            <Box id='esg_news' className="sec_bg py3" component="section">
                <Container maxWidth="lg" className="bdr">
                    <Grid container spacing={0} alignItems="center" justifyContent='flex-start'>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className="synopsic_bx">
                                <Typography variant='h6' className='black'>
                                    Elevate your ESG capabilities with Decimal Point Analytics. Our comprehensive suite of solutions empowers you to master ESG reporting and analytics, driving sustainable growth and long-term value. Align your strategies with regulatory demands and achieve excellence with our expertly designed tools and dedicated support.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box className='flx_center mt16'>
                                <ProductCTAA allignment="flx_fs" cta_btn_name="Contact us to learn more" pageVisited="Private Equity ESG Excellence Suite" />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


        </>
    );
}
